/**
* 描述： 项目归档
* 录入人： 王帅
*/
import request from '../request.js'


/**
* 描述：项目归档列表页数据查询
* 接口管理员：崔北超
* 参数： 
{
    projectId: '',          // 项目ID
    userName: '',          // 经办人
    tenantName: '',          // 协审单位
    auditType: '',          // 审计任务类型
    sponsorUnit: '',          // 委托单位
    ownerUnitName: '',          // 建设单位
    startDate: '',          // 开始时间
    endDate: '',          // 结束时间
}
*/
export const getListData = function (data) {
    return request({
        url: 'taskAssistant/selectPageForProjectArchive',
        method: 'post',
        data
    })
}

/**
* 描述：项目归档--查询可归档的任务
* 接口管理员：崔北超
* 参数： 
{
    projectId: '',          // 项目ID
    packSname: '',          // 标段名称或编号
}
*/
export const getSelectData = function (data) {
    return request({
        url: 'taskAssistant/selectSurplusByProjectId',
        method: 'post',
        data
    })
}

/**
* 描述：归档详细页 - 归档信息查询
* 接口管理员：崔北超
* 参数： 
{
    "taskId": '',                           // --任务id
    "projectId": '',                        // 项目id
    "packId": '',                           // 标段id
    "statusSign": '',                       // 标段id
    "auditType": '',                        // 审计任务类型码值
    "auditTaskArchiveSign": '',             // 这里是列表页归档按钮查询资料时传值
    "approveArchiveSign": '',               // 这里是审批查询资料时传值
    "recordId": '',                         // 审批id
}
*/
export const getDetailInfo = function (data) {
    return request({
        url: 'taskAssistant/getTaskArchiveInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}

/**
* 描述：项目归档--资料清单获取
* 接口管理员：崔北超
* 参数： 
{
    "taskId": '',                   // 任务id
    "projectId": '',                // 项目id
    "packId": '',                   // 标段id
    "auditType": '',                // 审计任务类型码值
    "auditTaskArchiveSign": '',     // 归档列表归档按钮数据回显传值
    "approveArchiveSign": '',       // 审批回显归档资料传值
    "recordId": '',                 // recordId
}
*/
export const getProjectFiles = function (data) {
    return request({
        url: 'taskAssistant/getProjectFiles',
        method: 'post',
        data,
        // requestConfig: {
        //     needTenantId: true
        // },
    })
}

/**
* 描述：项目归档--保存或提交
* 接口管理员：崔北超
* 参数： 
{
    "taskId": '',                   // 任务id
    "taskArchiveSign": '',          // 1 新增归档  2 归档编辑
    "id": '',                       // 列表数据id
    "projectId": '',                // 项目id
    "packId": '',                   // 标段id
    "archiveBelow": '',             // 线下是否归档    0：否    1：是
    "archiveAdd": '',               // 线下档案位置
    "archiveDate": '',              // 线下归档时间
    "declares": '',                 // 其他说明
    "agent": '',                    // 经办人
    "operateTime": '',              // 经办时间
    "status": '',                   // status  保存 1001026022    提交 1001026023
    "projectFiles": '',             // 资料数据--直接把树结构当参数
}
*/
export const saveOrUpdate = function (data) {
    return request({
        url: 'taskAssistant/saveOrUpdateAuditProjectArchive',
        method: 'post',
        data
    })
}
