<!--
    描述：详情页面
    Created by asq
-->
<template>
	<div class="audit-msg-layout">
		<el-button type="primary"
				   size="small"
				   class="btn1">审核</el-button>
		<el-button class="btn1"
				   size="mini"
				   @click="goBack">返回</el-button>
		<div class="overflow-y">
			<div class="msg-content">
				<el-collapse :value="[
                        'qyInfo',
                        'baseInfo',
                        'bank',
                        'link',
                        'licenseMsg',
                        'proveMaterial'
                    ]"
							 class="msg-collapse">
					<el-collapse-item title="#1 项目信息"
									  name="qyInfo">
						<el-form label-position="left"
								 label-width="225px"
								 size="mini"
								 :model="userInfo">
							<el-row>
								<el-col :span="12">
									<el-form-item label="工程项目名称：">
										<span v-if="companyMsg.adminName">
											{{companyMsg.adminName}}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="建设单位：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="总包施工单位：">
										<span v-if="companyMsg.isReal == 0">否</span>
										<span v-else-if="companyMsg.isReal == 1">是</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="建设单位项目负责人：">
										<span v-if="companyMsg.openSysNames">{{
                                            companyMsg.openSysNames
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目负责人联系方式：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目所在区域：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目地址：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目类型：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目年份：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="分管部门：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目状态：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目造价区间：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="建设任务书编号：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="建设工程概算价：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="招标控制价：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="合同价：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="结算价：">
										<span v-if="companyMsg.createDate">{{
                                            companyMsg.createDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</el-collapse-item>
					<el-collapse-item title="#2 业主/建设单位信息"
									  name="baseInfo">
						<el-form label-position="left"
								 label-width="225px"
								 size="mini"
								 :model="tenant">
							<el-row>
								<el-col :span="12">
									<el-form-item label="业主/建设单位：">
										<span v-if="tenant.nationality">{{
                                            tenant.nationality
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="业主单位地址：">
										<span v-if="tenant.companyTypeName">{{
                                            tenant.companyTypeName
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="业主/建设单位组织机构代码：">
										<span v-if="tenant.sname">{{
                                            tenant.sname
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="建设单位类别：">
										<span v-if="tenant.creditCode">{{
                                            tenant.creditCode
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="业主/建设单位联系人：">
										<span v-if="tenant.principal">{{
                                            tenant.principal
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="联系方式：">
										<span v-if="tenant.legal">{{
                                            tenant.legal
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</el-collapse-item>
					<el-collapse-item title="#3 项目资料"
									  name="bank">
						<vxe-table :data="userList"
								   align="center"
								   auto-resize
								   size="small"
								   highlight-current-row
								   highlight-hover-row
								   border>
							<vxe-table-column type="seq"
											  title="序号"
											  width="60">
							</vxe-table-column>
							<vxe-table-column field="sname"
											  title="附件"
											  width="200">
							</vxe-table-column>
							<vxe-table-column field="idCard"
											  title="资料类型"
											  width="200">
							</vxe-table-column>
							<vxe-table-column field="phone"
											  title="文件来源"
											  width="200">
							</vxe-table-column>
							<vxe-table-column field="post"
											  title="标签"
											  width="200">
							</vxe-table-column>
							<vxe-table-column field="entryTime"
											  title="层级"
											  width="200">
							</vxe-table-column>
							<vxe-table-column field="orgName"
											  title="上传时间"
											  width="220">
							</vxe-table-column>
							<vxe-table-column field="openSysName"
											  title="备注"
											  min-width="200">
							</vxe-table-column>
						</vxe-table>
					</el-collapse-item>
					<el-collapse-item title="#4 标段信息"
									  name="link"
									  :model="contact">
						<el-form label-position="left"
								 label-width="225px"
								 size="mini">
							<el-row>
								<el-col :span="12">
									<el-form-item label="标段编号：">
										<span v-if="contact.webSite">{{
                                            contact.webSite
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="标段名称：">
										<span v-if="contact.email">{{
                                            contact.email
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="项目标段负责人：">
										<span v-if="contact.contactUser">{{
                                            contact.contactUser
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="标段负责人联系方式：">
										<span v-if="contact.phone">{{
                                            contact.phone
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="标段类型：">
										<span v-if="contact.postalCode">{{
                                            contact.postalCode
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="合同工期：">
										<span v-if="contact.fax">{{
                                            contact.fax
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="标段内容建设与规模：">
										<span v-if="contact.postalCode">{{
                                            contact.postalCode
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="标段预算金额：">
										<span v-if="contact.fax">{{
                                            contact.fax
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="备注说明：">
										<span v-if="contact.fax">{{
                                            contact.fax
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
						<vxe-table :data="userList"
								   align="center"
								   auto-resize
								   size="small"
								   highlight-current-row
								   highlight-hover-row
								   border>
							<vxe-table-column type="seq"
											  title="序号"
											  width="10%">
							</vxe-table-column>
							<vxe-table-column field="sname"
											  title="附件"
											  width="20%">
							</vxe-table-column>
							<vxe-table-column field="idCard"
											  title="资料类型"
											  width="20%">
							</vxe-table-column>
							<vxe-table-column field="phone"
											  title="文件来源"
											  width="20%">
							</vxe-table-column>
							<vxe-table-column field="post"
											  title="上传时间"
											  min-width="30%">
							</vxe-table-column>
						</vxe-table>
					</el-collapse-item>
					<el-collapse-item title="#5 报审信息"
									  name="licenseMsg">
						<el-form label-position="left"
								 label-width="225px"
								 size="mini"
								 :model="license">
							<el-row>
								<el-col :span="12">
									<el-form-item label="委托方式：">
										<span v-if="license.licenseTypeName">{{
                                            license.licenseTypeName
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="审计任务类型：">
										<span v-if="license.licenseNo">{{
                                            license.licenseNo
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="委托审计单位：">
										<span v-if="license.domicile">{{
                                            license.domicile
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="审计要求完成时间：">
										<span v-if="license.orgName">{{
                                            license.orgName
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item class="even-border"
												  label="报审日期：">
										<span v-if="license.postilDate">{{
                                            license.postilDate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="备注：">
										<span v-if="license.indate">{{
                                            license.indate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="附件：">
										<span v-if="license.indate">{{
                                            license.indate
                                        }}</span>
										<span v-else>-</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</el-collapse-item>
					<el-collapse-item title="#6  审核信息"
									  name="proveMaterial">
						<vxe-table :data="userList"
								   align="center"
								   auto-resize
								   size="small"
								   highlight-current-row
								   highlight-hover-row
								   border>
							<vxe-table-column field="sname"
											  title="审批人"
											  width="15%">
							</vxe-table-column>
							<vxe-table-column field="idCard"
											  title="审批状态"
											  width="15%">
							</vxe-table-column>
							<vxe-table-column field="phone"
											  title="审批时间"
											  width="20%">
							</vxe-table-column>
							<vxe-table-column field="post"
											  title="审批结果"
											  width="15%">
							</vxe-table-column>
							<vxe-table-column field="111"
											  title="审批意见"
											  width="35%">
							</vxe-table-column>
						</vxe-table>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
		<!-- <div class="footer msg-content text-center mt10">
            <el-button class="btn cancel-btn" size="mini" @click="goBack">返回</el-button>
        </div> -->
	</div>

</template>

<script>
import { mapState } from 'vuex'

export default {
	components: {},
	props: {
		companyId: {
			type: String,
			default: ''
		},
		companyMsg: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			userInfo: {}, //个人账户信息
			tenant: {}, //企业基本信息
			contact: {}, //联系人信息
			bank: {}, //银行
			license: {}, //证照信息
			evidentiary: {}, //证照材料
			finance: {}, //财务情况
			qualification: {}, //资质信息
			userList: []
		}
	},
	created() {
		// this.getPersonData()
	},
	methods: {
		// 返回
		goBack() {
			this.$router.push({ path: '/approval' })
		},
		//获得列表数据
		getPersonData() {
			getBaseInfo({
				id: this.userInfo.id,
				type: '0'
			}).then(res => {
				this.personForm = params
			})
		},

		// 切换显示条数
		handleSizeChange(val) {
			this.pageSize = val
			this.getData()
		},

		//切换当前页码
		handleCurrentChange(val) {
			this.currentPage = val
			this.getData()
		},

		// 是否展开搜索区域
		changeState() {
			this.searchToggle = !this.searchToggle
		},

		// 搜索
		searchResult() {
			this.currentPage = 1
			this.pageSize = 10
			this.getData()
		}
	},
	computed: {}
}
</script>

<style lang="less" scoped>
.audit-msg-layout {
	padding: 0px 0;
	height: 100%;
	text-align: right;
	position: relative;
	.btn1 {
		margin: 5px;
	}
	.overflow-y {
		height: 100% - 5px;
		overflow-y: auto;
	}
	.msg-head {
		background-color: #496dd6;
		padding-left: 20px;
		height: 34px;
		line-height: 34px;
		margin-top: 15px;
		font-size: 14px;
		color: #fff;
	}
	.msg-content {
		/deep/ .el-collapse-item {
			margin-bottom: 12px;
		}
		/deep/ .el-collapse-item__header {
			padding-left: 20px;
			background: #e4e7ed;
			color: #202020;
			height: 38px;
			line-height: 38px;
			font-weight: bolder;
		}
		/deep/.el-collapse-item__wrap {
			border-bottom: none;
		}
		/deep/ .el-collapse-item__content {
			padding-bottom: 0;
		}
		.no-have {
			text-align: center;
			padding: 20px 0;
			border: 1px solid #e5e5e5;
			border-top: none;
			color: #666666;
		}
		.el-form-item {
			margin-bottom: 0;
		}
		/deep/ .el-form-item__label {
			background: #f2f6fc;
			word-break: break-all;
			padding: 0 10px;
			height: 38px;
			line-height: 38px;
			border: 1px solid #dcdcdc;
			border-top: none;
			font-size: 12px;
			color: #666666;
		}
		/deep/ .el-form-item__content {
			padding-left: 15px;
			border-bottom: 1px solid #e5e5e5;
			border-right: 1px solid #e5e5e5;
			height: 38px;
			line-height: 38px;
		}
	}
	.even-border {
		/deep/ .el-form-item__label {
			border-left: none;
		}
	}
	.long-label {
		/deep/ .el-form-item__label {
			width: 225px !important;
		}
	}
	.special-item {
		/deep/ .el-form-item__label {
			height: 150px;
			line-height: 150px;
		}
		/deep/ .el-form-item__content {
			height: 150px;
			line-height: 30px;
		}
	}
	.footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 10px 0;
		background-color: #fff;
		box-shadow: 0px 0px 30px 0px rgba(201, 201, 201, 0.5);
		z-index: 3;
	}
	.red {
		color: red;
		font-weight: bolder;
	}
	.text-overflow-5 {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
	}
}
</style>

