<!--
    描述：项目归档 --- 详细内容展示、操作页面
    时间：2021-08-04
    Created by 王帅
-->
<template>
    <div class="info-layout">
        <div class="right-btns"
			 v-show="auditVisble && !isViewMode">
			<el-button size="mini"
					   type="primary"
					   @click="auditShow">审核</el-button>
			<el-button size="mini"
					   @click="backApproval">取消</el-button>
		</div>
        <div class="info-content">
            <el-card
                v-for="(item, index) in cardShowList"
                :key="'card' + index"
                class="info-card"
            >
                <!-- 标题 -->
                <div
                    slot="header"
                    class="info-card-header"
                    @click="item.showContent = !item.showContent"
                >
                    <span class="card-header-icon"
                        ><i
                            :class="
                                item.showContent
                                    ? 'el-icon-caret-top'
                                    : 'el-icon-caret-bottom'
                            "
                        ></i
                    ></span>
                    <span>{{ `#${index + 1} ${item.title}` }}</span>
                </div>

                <!-- 内容 -->
                <div class="info-card-content pdt10" v-show="item.showContent && item.show">
                    <!-- 归档信息 -->
                    <template v-if="item.attr === 'baseInfo'">
                        <el-form label-width="260px" size="mini">
                            <template
                                v-for="(item, index) in entrustList"
                            >
                                <el-row class="view-form">
                                    <el-col :span="24">
                                        <el-form-item
                                            :label="dealSubType(item.subType) + '：'"
                                        >
                                            {{ item.unitName }}
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row class="view-form">
                                    <el-col :span="12">
                                        <el-form-item
                                            :label="
                                                dealSubType(item.subType) + '任务负责人：'
                                            "
                                        >
                                            {{ item.taskPrincipal }}
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="任务负责人联系方式：">{{
                                            item.constract
                                        }}</el-form-item>
                                    </el-col>
                                </el-row>
                            </template>

                            <p class="cutting-line"></p>
                            <!-- 基本信息 -->
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="工程项目名称：">{{
                                        baseInfo.projectName
                                    }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="项目类型：">{{
                                        baseInfo.proTypeName
                                    }}</el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="标段编号：">{{
                                        baseInfo.packNo
                                    }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="标段名称：">{{
                                        baseInfo.packName
                                    }}</el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="审计任务编号：">{{
                                        baseInfo.orderNo
                                    }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="审计任务类型：">{{
                                        baseInfo.auditTypeName
                                    }}</el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="委托方式：">{{
                                        baseInfo.entrustTypeName
                                    }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="审计要求完成时间：">{{
                                        baseInfo.finshDate
                                    }}</el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="委托日期：">{{
                                        baseInfo.taskEntrustDate
                                    }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="受理日期：">{{
                                        baseInfo.taskAcceptDate
                                    }}</el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                        <p class="cutting-line"></p>
                        <!-- 保存的 form -->
                        <el-form label-width="260px" :model="saveForm" size="mini">
                            <el-row class="view-form">
                                <el-col :span="24">
                                    <el-form-item label="线下是否归档：">
                                        {{ saveForm.archiveBelow ? '已归档' : '未归档' }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="线下档案位置：">
                                        {{ saveForm.archiveAdd }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="线下归档时间：">
                                        {{ saveForm.archiveDate }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="24" class="sp">
                                    <el-col :span="4">其他说明：</el-col>
                                    <el-col :span="20">
                                        {{ saveForm.declares || '无' }}
                                    </el-col>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="经办人：">
                                        {{ saveForm.agent }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="经办单位：">
                                        {{ saveForm.handleUnit }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="操作时间：">
                                        {{ saveForm.operateTime }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </template>

                    <!-- 跟踪审计任务 -->
                    <template v-if="item.attr === 'tracking'">
                        <el-form ref="taskForm" :show-message="false" :label-width="isViewMode ? '200px' : '260px'" size="mini" :model="taskForm">
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="标段控制价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.controlPrice }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="标段合同价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.contractPrice }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item prop="reserveCon">
                                        <template slot="label">
                                            其中暂列金额
                                            <el-tooltip placement="top-start" 
                                                effect="light" 
                                                content="暂列金额、预留金、总承包服务费等包含在合同内，但不属于承包人直接拥有的费用">
                                                    <svg-icon icon-class="wenhao"></svg-icon>
                                            </el-tooltip>：
                                        </template>
                                        <div class="text-overflow" >
                                            {{ taskForm.reserveCon }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="reservePact">
                                        <template slot="label">
                                            其中暂列金额
                                            <el-tooltip placement="top-start" 
                                                effect="light" 
                                                content="暂列金额、预留金、总承包服务费等包含在合同内，但不属于承包人直接拥有的费用">
                                                    <svg-icon icon-class="wenhao"></svg-icon>
                                            </el-tooltip>：
                                        </template>
                                        <div class="text-overflow" >
                                            {{ taskForm.reservePact }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="不含暂列金额的控制价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.noResCon }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="不含暂列金额的合同价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.noResPact }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <p class="cutting-line"></p>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="累计申报产值款期数：">
                                        <div class="text-overflow" >
                                            {{ taskForm.periodsCon }} 期
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="累计申报进度款期数：">
                                        <div class="text-overflow" >
                                            {{ taskForm.periodsPact }} 期
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="施工方累计申报产值：">
                                        <div class="text-overflow" >
                                            {{ taskForm.decValCon }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="施工方累计申报进度款：">
                                        <div class="text-overflow" >
                                            {{ taskForm.decValPact }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="协审累计审核产值：">
                                        <div class="text-overflow" >
                                            {{ taskForm.audValCon }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="协审累计审核进度款金额：">
                                        <div class="text-overflow" >
                                            {{ taskForm.audValPact }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="累计批准产值：">
                                        <div class="text-overflow" >
                                            {{ taskForm.appValCon }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="累计批准进度款金额：">
                                       <div class="text-overflow" >
                                            {{ taskForm.appValPact }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="标段初审价：">
                                       <div class="text-overflow" >
                                            {{ taskForm.packVal }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="24">
                                    <el-form-item label="备注：">
                                        <div class="text-overflow" >
                                            {{ taskForm.remark }}
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </template>

                    
                    <!-- 结算审计任务 -->
                    <template v-if="item.attr === 'settle'">
                        <el-form ref="taskForm" :label-width="isViewMode ? '200px' : '260px'" size="mini" :model="taskForm">
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="标段控制价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.controlPrice }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="标段合同价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.contractPrice }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item prop="reserveCon">
                                        <template slot="label">
                                            其中暂列金额
                                            <el-tooltip placement="top-start" 
                                                effect="light" 
                                                content="暂列金额、预留金、总承包服务费等包含在合同内，但不属于承包人直接拥有的费用">
                                                    <svg-icon icon-class="wenhao"></svg-icon>
                                            </el-tooltip>：
                                        </template>
                                        <div class="text-overflow" >
                                            {{ taskForm.reserveCon }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="reservePact">
                                        <template slot="label">
                                            其中暂列金额
                                            <el-tooltip placement="top-start" 
                                                effect="light" 
                                                content="暂列金额、预留金、总承包服务费等包含在合同内，但不属于承包人直接拥有的费用">
                                                    <svg-icon icon-class="wenhao"></svg-icon>
                                            </el-tooltip>：
                                        </template>
                                        <div class="text-overflow" >
                                            {{ taskForm.reservePact }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="不含暂列金额的控制价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.noResCon }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="不含暂列金额的合同价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.noResPact }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 分割线 -->
                            <p class="cutting-line"></p>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="结算报审价：">
                                        <div class="text-overflow" >
                                            {{ taskForm.auditPrice }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结算净审增/减额：">
                                        <div class="text-overflow" >
                                            {{ auditAddOrReducePrice }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="结算审定价：">
                                       <div class="text-overflow" >
                                            {{ taskForm.officialPrice }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结算审增率：">
                                        <div class="text-overflow" >
                                            {{ auditIncrementRate }} %
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="结算审增金额：">
                                        <div class="text-overflow" >
                                            {{ taskForm.trialAmount }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结算审减率：">
                                        <div class="text-overflow" >
                                            {{ auditReductionRate }} %
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="12">
                                    <el-form-item label="结算审减金额：">
                                        <div class="text-overflow" >
                                            {{ taskForm.subAmount }} 元
                                        </div>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="结算净审增/减率：">
                                        <div class="text-overflow" >
                                            {{ auditIncrementOrReductionRate }} %
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row class="view-form">
                                <el-col :span="24">
                                    <el-form-item label="备注：">
                                        <div class="text-overflow" >
                                            {{ taskForm.remark }}
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </template>

                    <!-- 资料归档清单 -->
                    <template v-if="item.attr === 'inventory'">
                        <div class="pd-table">
                            <div class="text-right btnGroups">
                                <el-select
                                    placeholder="请选择"
                                    v-model="curLevel"
                                    @change="changeLevel"
                                    size="small"
                                >
                                    <el-option v-for="n in maxLevel" :key="'level' + n" :value="n" :label="`展开到${n}级`"></el-option>
                                </el-select>
                            </div>
                            <vxe-table
                                auto-resize
                                border
                                ref="fileTree"
                                :data="fileData"
                                resizable
                                row-id="id"
                                min-height="400px"
                                highlight-current-row
                                highlight-hover-row
                                :loading="loading"
                                align="center"
                                show-overflow="tooltip"
                                show-header-overflow="tooltip"
                                :tree-config="{ children: 'children', expandAll: true }"
                            >
                                <vxe-table-column
                                    type="seq"
                                    title="序号"
                                    width="65px"
                                    header-align="center"
                                    align="left"
                                ></vxe-table-column>
                                <vxe-table-column
                                    field="sname"
                                    title="资料清单"
                                    tree-node
                                    min-width="220px"
                                    header-align="center"
                                    align="left"
                                >
                                    <template #default="{ row }">
                                        <i
                                            v-if="row.pid == '0'"
                                            class="el-icon-folder-opened mr5"
                                        ></i>
                                        <i v-else class="el-icon-tickets mr5"></i
                                        >{{ row.sname }}
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column
                                    field="sname2"
                                    title="资料名称"
                                    width="180px"
                                >
                                    <template #default="{ row }">
                                        <FileName v-if="row.sysFile" 
                                        :fileName="row.sysFile.fileName" 
                                        :filePath="row.sysFile.filePath"
                                        :fileSize="row.sysFile.fileSize"></FileName>
                                        <!-- <span
                                            v-if="row.sysFile"
                                            class="file-name vxe-cell--label"
                                            @click="onPreviewFile(row.sysFile)"
                                        >
                                            {{ row.sysFile.fileName }}
                                        </span> -->
                                        <div v-else>--</div>
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column
                                    field="fileSize"
                                    title="文件大小"
                                    width="100px"
                                    align="right"
                                    header-align="center"
                                >
                                    <template #default="{ row }">
                                        <span class="vxe-cell--label" v-if="row.sysFile">
                                            {{ row.sysFile.size + " kb" }}
                                        </span>
                                        <div v-else>--</div>
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column field="md5" title="MD5" width="150px">
                                    <template #default="{ row }">
                                        <span class="vxe-cell--label" v-if="row.sysFile">
                                            {{ row.sysFile.md5 }}
                                        </span>
                                        <div v-else>--</div>
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column
                                    field="redactUnit"
                                    title="文件编制单位"
                                    min-width="120px"
                                    header-align="center"
                                    align="left"
                                >
                                    <template #default="{ row }">
                                        <span class="vxe-cell--label" v-if="row.sysFile">
                                            {{ row.sysFile.redactUnit }}
                                        </span>
                                        <div v-else>--</div>
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column
                                    field="createUser"
                                    title="上传人"
                                    width="130px"
                                >
                                    <template #default="{ row }">
                                        <span class="vxe-cell--label" v-if="row.sysFile">
                                            {{ row.sysFile.createUser }}
                                        </span>
                                        <div v-else>--</div>
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column
                                    field="shortName"
                                    title="上传单位"
                                    min-width="120px"
                                >
                                    <template #default="{ row }">
                                        <el-tooltip
                                            effect="dark"
                                            :content="row.sysFile.upUnit"
                                            placement="top"
                                            v-if="row.sysFile"
                                        >
                                            <span class="vxe-cell--label">{{
                                                row.sysFile.shortName
                                            }}</span>
                                        </el-tooltip>
                                        <div v-else>--</div>
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column
                                    field="createDate"
                                    title="上传时间"
                                    width="160px"
                                >
                                    <template #default="{ row }">
                                        <div v-if="row.sysFile">
                                            {{ row.sysFile.createDate }}
                                        </div>
                                        <div v-else>--</div>
                                    </template>
                                </vxe-table-column>
                                <vxe-table-column
                                    field="isPlace"
                                    title="是否归档"
                                    width="120px"
                                >
                                    <template #default="{ row }">
                                        <!-- <vxe-switch
                                            v-model="row.archiveFlag"
                                            open-label="是"
                                            :open-value="1"
                                            close-label="否"
                                            :close-value="0"
                                        ></vxe-switch> -->
                                        {{ row.archiveFlag ? '是' : '否' }}
                                    </template>
                                </vxe-table-column>
                            </vxe-table>
                        </div>
                    </template>

                    <!-- 审核记录 -->
                    <template v-if="item.attr === 'checkInfo'">
                        <vxe-table
                                auto-resize
                                border
                                ref="recordsTable"
                                :data="recordData"
                                resizable
                                min-height="400px"
                                highlight-current-row
                                highlight-hover-row
                                align="center"
                                show-header-overflow="tooltip"
                            >
                            <vxe-table-column field="userName"
                                        title="审批人/移交人"></vxe-table-column>
                            <vxe-table-column field="shortName"
                                            title="审批单位"
                                            header-align="center"
                                            align="left">
                                <template #default="{ row }">
                                    <el-tooltip effect="dark"
                                                :content="row.deptName"
                                                placement="top">
                                        <span class="vxe-cell--label">{{ row.shortName }}</span>
                                    </el-tooltip>
                                </template>
                            </vxe-table-column>
                            <vxe-table-column field="handleDate"
                                            title="审批时间/移交时间">
                                <template #default="{ row }">
                                    <el-tooltip effect="dark"
                                                :content="row.handleDate"
                                                :disabled="row.handleDate == ''"
                                                placement="top">
                                        <span class="vxe-cell--label"
                                            v-if="row.handleDate">
                                            {{ row.handleDate.substr(0, 11) }}
                                        </span>
                                        <span v-else>-</span>
                                    </el-tooltip>
                                </template>
                            </vxe-table-column>
                            <vxe-table-column
                                field="status"
                                title="审批结果"
                            >
                                <template #default="{ row }">
                                    <span v-if="row.status == '1001026002'">通过</span>
                                    <span v-else-if="row.status == '1001026003'">驳回</span>
                                    <span v-else>-</span>
                                </template>
                            </vxe-table-column>
                            <vxe-table-column
                                field="remark"
                                title="审批意见"
                                header-align="center"
                                align="left"
                            >
                            </vxe-table-column>
                            <vxe-table-column field=""
                                        title="备注">
                                <template #default="{ row }">
                                    <span v-if="row.status == '1001026041'">移交</span>
                                    <span v-else>-</span>
                                </template>
                            </vxe-table-column>
                        </vxe-table>
                    </template>
                </div>
            </el-card>
        </div>

        <!-- 审核弹窗 -->
		<el-dialog :title="auditObj.title"
				   :visible.sync="auditObj.show"
				   :before-close="auditCancel"
				   :close-on-click-modal="false"
				   width="800px"
				   append-to-body
				   center>
			<el-form :model="auditObj"
					 label-width="120px"
					 ref="auditForm"
					 size="small">
                <el-form-item label="审核结果"
							  prop="status"
							  :rules="requireRule">
					<el-radio-group v-model="auditObj.status"
									@change="radioChange">
						<el-radio label="1001026002">通过</el-radio>
						<el-radio label="1001026003">驳回</el-radio>
					</el-radio-group>
                </el-form-item>
				<el-form-item label="审核意见"
							  prop="remark"
							  :rules="auditObj.status == '1001026003' ? requireRule : []"
							  class="mb30">
					<el-input type="textarea"
							  :autosize="{ minRows: 6 }"
							  :maxlength="200"
							  show-word-limit
							  placeholder="请输入内容"
							  v-model="auditObj.remark">
					</el-input>
				</el-form-item>
				<el-form-item>
					<div class="tip-box">
						<p><i class="el-icon-warning"></i>备注：驳回，必须在审核意见内填写驳回理由。</p>
						<p class="left-indent">审核结束：该审批流程到此结束。</p>
						<p class="left-indent">继续提交审核：继续为该审批流程选择下一步的提交审核对象。</p>
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button class="btn"
						   @click="auditCancel"
						   size="small">取消</el-button>
				<el-button class="btn btn-success"
						   size="small"
						   @click="auditConfirm"
						   :loading="btnLoading">审核结束</el-button>
				<el-button class="btn"
						   type="primary"
						   size="small"
						   :disabled="auditObj.status == '1001026003'"
						   @click="handOther"
						   :loading="btnLoading">继续提交审核</el-button>
			</div>
		</el-dialog>

		<!-- 移交他人审核 -->
		<AuditPersonList v-if="auditPersonShow"
						 ref="AuditPersonList"
						 :rowData="rowInfo"
						 doType="audit"
						 :auditForm="auditObj"
						 @closeDia="closeDia"></AuditPersonList>
    </div>
</template>

<script>
import * as Api from "api/approval/archive.js";
import VUE_CONFIG from "../../../vue.config.js";
import { mapState } from "vuex";
import * as ApprovalApi from 'api/approval/approval'
import AuditPersonList from '@/views/Common/AuditPersonList'
export default {
    name: "",
    components: { AuditPersonList },
    data() {
        return {
            cardList: [
                {
                    title: "归档信息",
                    attr: "baseInfo",
                    showContent: true,
                    show: true,
                },
                {
                    title: "跟踪审计任务",
                    attr: "tracking",
                    showContent: true,
                    show: false,
                },
                {
                    title: "结算审计任务",
                    attr: "settle",
                    showContent: true,
                    show: false,
                },
                {
                    title: "资料归档清单",
                    attr: "inventory",
                    showContent: true,
                    show: true,
                },
                {
                    title: "审核记录",
                    attr: "checkInfo",
                    showContent: true,
                    show: true,
                },
            ],
            // 委托信息数组
            entrustList: [],
            // 返回的信息
            baseInfo: {
                projectName: "",
                proTypeName: "",
                packNo: "",
                packName: "",
                orderNo: "",
                auditTypeName: "",
                entrustTypeName: "",
                finshDate: "",
                taskEntrustDate: "",
                taskAcceptDate: "",
            },
            saveForm: {
                taskArchiveSign: 1, // 1 新增归档  2 归档编辑
                archiveBelow: 0,
                archiveAdd: "",
                archiveDate: "",
                declares: "",
                agent: "",
                handleUnit: "",
                operateTime: '',
                // status: 0
            },
             // 审计任务
            taskForm: {
                controlPrice: '',  // 标段控制价
                contractPrice: '',  // 标段合同价
                reserveCon: '',  // 控制价暂列金
                reservePact: '',  // 合同价暂列金
                noResCon: '',  // 不含暂列金控制价
                noResPact: '',  // 不含暂列金合同价
                periodsCon: '', // 累计申报产值期数（控制价）
                periodsPact: '', // 累计申报产值期数（合同价）
                decValCon: '', // 施工方累计申报产值(控制价)
                decValPact: '', // 施工方累计申报进度款（合同价）
                audValCon: '', // 协审累计审核产值(控制价)
                audValPact: '', // 协审累计审核进度款金额（合同价）
                appValCon: '', // 累计批准产值(控制价)
                appValPact: '', // 累计批准进度款金额（合同价）
                remark: '', // 备注
                packVal: '', // 初审报告价
                auditPrice: '', // 结算报审价
                auditAddOrReducePrice: '',  // 结算净审增/减额
                officialPrice: '',  // 结算审定价
                auditIncrementRate: '',  // 结算审增率
                trialAmount: '',  // 结算审增金额
                auditReductionRate: '',  // 结算审减率
                subAmount: '',  // 结算审减金额
                auditIncrementOrReductionRate: '',  // 结算净审增/减率
            },
            // 资料清单
            fileData: [],
            recordData: [],
            loading: false,
            selectFiles: [],
            fileVisible: false, //文件弹窗
            curLevel: 1,
            maxLevel: 1,
            rowInfo: null,
            btnLoading: false,
            //审核
			auditObj: {
				status: '1001026002',
				remark: '',
				title: '审核',
				show: false,
			},
			auditPersonShow: false,
			requireRule: [{ required: true, message: '不能为空...' }],
        };
    },
    created() {
        // 获取基础信息
        this.rowInfo = this.$getSessionItem("archiveInfo")
            ? JSON.parse(this.$getSessionItem("archiveInfo"))
            : null;

        if (this.rowInfo) {
            // 信息
            this.getBaseInfo(this.rowInfo);

            // 如果是跟踪审计
            this.cardList[1].show = this.rowInfo.auditType === '1006004001'

            // 结算审计
            this.cardList[2].show = this.rowInfo.auditType === '1006004002'

            // 资料
            this.getProjectFiles(this.rowInfo);

            // 获取审批信息
		    this.getApprovalList()
        } else {
            this.backApproval()
        }
    },
    methods: {
        // 移交其他人员
		handOther() {
			this.auditPersonShow = true
            this.$refs.auditForm.validate((valid) => {
				if (valid) {
					setTimeout(() => {
                        this.$refs.AuditPersonList.auditPersonObj.show = true
                    }, 200)
					return
				}
				this.$message.error('请完善表单！')
			})
			
		},

		// 接收弹窗关闭
        closeDia(data) {
            this.auditPersonShow = false
            if(data){
                this.backApproval()
            }
        },

		// change事件
		radioChange(data) {
			setTimeout(() => {
				this.$refs.auditForm.clearValidate()
			}, 100)
		},

		// 获取审批信息
		getApprovalList() {
			ApprovalApi.selectApproveRecordById({
				id: this.rowInfo.id,
				module: 4,
			}).then((res) => {
				this.recordData = res.data
			})
		},

        //展示审核
		auditShow() {
			this.auditObj.show = true
		},

		// 取消
		backApproval() {
			this.$router.push({ path: '/redirect/approval' })
		},

        //审核取消
		auditCancel() {
			this.auditObj.show = false
			this.$refs.auditForm.resetFields()
		},

		//审核提交
		auditConfirm() {
            this.$refs.auditForm.validate((valid) => {
				if (valid) {
					this.btnLoading = true
                    let params = Object.assign({}, this.auditObj)
                    params.busId = this.rowInfo.busId
                    ApprovalApi.getSubmitAdd(params).then((res) => {
                        if (res.operationCode != 1 || res.msg == 'faild') {
                            this.btnLoading = false
                            this.$message.error(res.data)
                            return
                        }
                        this.btnLoading = false
                        this.$emit('resetTable')
                        this.$router.push({ path: '/approval' })
                    })
					return
				}
				this.$message.error('请完善表单！')
			})
		},

        // 处理类型
        dealSubType(type) {
            let msg = "";
            switch (type) {
                case "1":
                    msg = "建设单位";
                    break;
                case "2":
                    msg = "委托审计单位";
                    break;
                case "3":
                    msg = "委托协审单位";
                    break;
                default:
                    msg = "";
            }
            return msg;
        },

        // 获取标段信息
        getBaseInfo(params) {
            Api.getDetailInfo(params)
                .then((res) => {
                    this.entrustList = res.data.taskInfo;

                    // 项目信息
                    if (res.data && res.data.project) {
                        this.baseInfo.projectName = res.data.project.sname;
                        this.baseInfo.proTypeName = res.data.project.proTypeName;
                    }

                    // 标段信息
                    if (res.data && res.data.pack) {
                        this.baseInfo.packNo = res.data.pack.packNo;
                        this.baseInfo.packName = res.data.pack.sname;
                    }

                    // 审计任务
                    if (res.data && res.data.auditArchiveDetail) {
                        Object.keys(res.data.auditArchiveDetail).map(x => {
                            if (res.data.auditArchiveDetail[x] === null) {
                                res.data.auditArchiveDetail[x] = ''
                            }
                        })
                        this.taskForm = res.data.auditArchiveDetail
                    }

                    // 任务信息
                    if (res.data && res.data.assistantTask) {
                        this.baseInfo.orderNo = res.data.assistantTask.orderNo;
                        this.baseInfo.auditTypeName =
                            res.data.assistantTask.auditTypeName;
                        this.baseInfo.entrustTypeName =
                            res.data.assistantTask.entrustType === 1
                                ? "委托审计机关"
                                : "自行委托";
                        this.baseInfo.finshDate = res.data.assistantTask.finshDate;
                        this.baseInfo.taskEntrustDate =
                            res.data.assistantTask.taskEntrustDate;
                        this.baseInfo.taskAcceptDate =
                            res.data.assistantTask.taskAcceptDate;
                    }

                    // 如果是编辑，回显一些信息
                    if (params.mode !== "add" && res.data && res.data.archiveInfo) {
                        this.saveForm.archiveBelow = res.data.archiveInfo.archiveBelow;
                        this.saveForm.archiveAdd = res.data.archiveInfo.archiveAdd;
                        this.saveForm.archiveDate = res.data.archiveInfo.archiveDate;
                        this.saveForm.declares = res.data.archiveInfo.declares;
                        this.saveForm.agent = res.data.archiveInfo.agent;
                        this.saveForm.handleUnit = res.data.archiveInfo.agent;
                        this.saveForm.operateTime = res.data.archiveInfo.operateTime;
                    }
                })
                .catch((err) => {});
        },

        // 获取资料清单
        getProjectFiles(row) {
            this.loading = true;
            let params = {
                busId: row.busId, // 任务id
                projectId: row.projectId, // 项目id
                packId: row.packId, // 标段id
                auditType: row.auditType, // 审计任务类型码值
                // auditTaskArchiveSign: "", // 归档列表归档按钮数据回显传值
                approveArchiveSign: "1", // 审批回显归档资料传值
                // recordId: "", // recordId
            };

            row.mode !== "add" ? (params.auditTaskArchiveSign = "1") : "";
            Api.getProjectFiles(params)
                .then((res) => {
                    this.fileData = res.data.fileList;
                    this.maxLevel = res.data.level ? parseInt(res.data.level) + 1 : 1
                    this.curLevel = this.maxLevel
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        // 预览附件
        onPreviewFile(data) {
            let arr = data.fileName.split(".");
            let fileFormat = arr[arr.length - 1];
            if (this.acceptType.includes(fileFormat)) {
                let uploadUrl = "";
                if (process.env.VUE_APP_UPLOAD_URL == "/uploadApi") {
                    uploadUrl = VUE_CONFIG.devServer.proxy["/uploadApi"].target;
                } else {
                    uploadUrl = process.env.VUE_APP_UPLOAD_URL;
                }

                window.open(this.$previewUrl + uploadUrl + data.filePath, "_blank");
            } else {
                window.open(process.env.VUE_APP_UPLOAD_URL + data.filePath);
            }
        },

        // 切换展开层级
        changeLevel(level) {
            // 先关闭所有
            this.$refs.fileTree[0].clearTreeExpand();

            // 找到对应层级的数组
            let arr = this.$utils.filterTree(this.fileData, (x) => x.level < level - 1);
            this.$refs.fileTree[0].setTreeExpand(arr, true);
        },

        // 保存数据
        doSave(status) {
            this.btnLoading = true;
            let params = this.$utils.clone(this.saveForm, true);

            params.taskId = this.rowInfo.taskId;
            params.taskArchiveSign = this.rowInfo.mode === "add" ? 1 : 2;
            params.id = this.rowInfo.id;
            params.projectId = this.projectId;
            params.packId = this.rowInfo.packId;
            params.status = status;
            params.projectFiles = this.fileData;

            Api.saveOrUpdate(params)
                .then((res) => {
                    setTimeout(() => {
                        this.btnLoading = false;
                        this.doBack();
                    }, 1000);
                })
                .catch((err) => {
                    this.btnLoading = false;
                });
        },

        // 取消、返回
        doBack() {
            this.$router.push({
                path: "/ProjectArchiveIndex",
            });
        },
    },
    computed: {
        ...mapState("app", {
            projectId: (state) => state.projectId,
            projectInfo: (state) => state.projectInfo,
        }),

        ...mapState("user", {
            userInfo: (state) => state.userInfo,
        }),

        acceptType() {
            return [
                "jpg",
                "JPG",
                "png",
                "PNG",
                "pdf",
                "PDF",
                "excel",
                "EXCEL",
                "doc",
                "DOC",
                "docx",
                "DOCX",
                "txt",
                "TXT",
                "xls",
                "XLS",
                "xlsx",
                "XLSX",
                "ppt",
                "PPT",
                "pptx",
                "PPTX",
            ];
        },

        // 是否是查看模式
        isViewMode() {
            return this.rowInfo.mode === "view";
        },

        // 要展示的卡片数组
        cardShowList() {
            return this.cardList.filter(x => x.show)
        },

        // 按钮组
        btnGroups() {
            let arr = [];

            if (!this.isViewMode) {
                arr.push({
                    type: "primary",
                    text: "保存",
                    btnLoading: this.btnLoading,
                    function: () => {
                        this.doSave("1001026022");
                    },
                });

                arr.push({
                    type: "success",
                    text: "提交",
                    btnLoading: this.btnLoading,
                    function: () => {
                        this.doSave("1001026023");
                    },
                });
            }

            arr.push({
                type: "",
                text: "取消",
                btnLoading: false,
                function: () => {
                    this.doBack();
                },
            });

            return arr;
        },
        //审核按钮可见
		auditVisble() {
			return this.$route.query.menu === 'approval'
		},

        // 结算审计------
        // 结算净审增/减额
        auditAddOrReducePrice() {
            // 审增
            let _plus = parseFloat(this.taskForm.trialAmount) || 0
            // 审减
            let _minus = parseFloat(this.taskForm.subAmount) || 0

            // 结算净审增/减额
            return _plus - _minus
        },

        // 结算审增率
        auditIncrementRate() {
            // 审增
            let _plus = parseFloat(this.taskForm.trialAmount) || 0

            // 结算报审价
            let _price = parseFloat(this.taskForm.auditPrice) || 0

            return _price === 0 ? '' : (parseFloat((_plus / _price).toFixed(4)) * 100).toFixed(2)
        },

        // 结算审减率
        auditReductionRate() {
            // 审减
            let _minus = parseFloat(this.taskForm.subAmount) || 0

            // 结算报审价
            let _price = parseFloat(this.taskForm.auditPrice) || 0

            return _price === 0 ? '' : (parseFloat((_minus / _price).toFixed(4)) * 100).toFixed(2)
        },

        // 结算净审增/减率
        auditIncrementOrReductionRate() {
            // 结算净审增/减额
            let _minus = parseFloat(this.auditAddOrReducePrice) || 0

            // 结算报审价
            let _price = parseFloat(this.taskForm.auditPrice) || 0

            return _price === 0 ? '' : Math.abs((parseFloat((_minus / _price).toFixed(4)) * 100)).toFixed(2)
        }
    },
    // 离开前清除缓存
    beforeDestroy() {
        if (this.$getSessionItem("archiveInfo")) {
            this.$removeSessionItem('archiveInfo')
        }
    }
};
</script>

<style scoped lang="less">
.info-layout {
    height: 100%;
    // position: relative;

    .info-header {
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
    }

    .info-content {
        height: 100%;
        overflow-y: auto;
        padding: 0 10px;
    }

    .file-name {
        cursor: pointer;
        color: #60adf9;
        text-decoration: underline;
    }

    .view-form {
		/deep/ .el-form-item__label {
			padding: 9px !important;
			text-align: right;
			background-color: #f5f5f5;
			border: 1px solid #eee !important;
		}
		/deep/ .el-form-item__content {
			height: 48px !important;
			border: 1px solid #eee !important;
			display: flex;
			align-items: center;
			padding-left: 15px;
		}
        
        .sp {
			display: flex;
            .el-col-4,
            .el-col-20 {
                padding: 10px 15px !important;
            }
            .el-col-4 {
                width: 260px;
                text-align: right;
                background-color: #f7f7f7;
                border: 1px solid #eee;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                color: #606266;
                font-size: 14px;
            }
            .el-col-20 {
                width: calc(~'100% - 260px');
                border: 1px solid #eee;
                color: #999;
                font-size: 14px;
                line-height: 24px;
            }
        }
	}

    .right-btns {
		position: absolute;
		right: 0;
		top: 66px;
		padding: 0 10px;
		background-color: #fff;
		z-index: 10;
	}
}
.btn {
    padding: 0 50px !important;
}
.info-card-content {
    /deep/ .el-form-item {
        margin-bottom: 0px;
        color: #999;
    }

    // .cutting-line {
    //     border: 1px dashed #ddd;
    //     margin: 5px 50px;
    // }

    .btnGroups {
        line-height: 30px;
        margin-bottom: 10px;
    }

    .pd-table {
        padding: 0 20px 20px;
    }
}
.tip-box {
	line-height: 25px;
	background: #ecf5ff;
	border-radius: 5px;
	border: 1px solid #d9ecff;
	color: #419eff;
	padding: 5px 15px;
	.left-indent {
		text-indent: 3.5rem;
	}
}
</style>
