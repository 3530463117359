<!--
    描述：快捷菜单子菜单--- 我的审批
    时间：2021-03-26
    Created by 王帅

	edit by 昂少强
-->
<template>
	<div class="my-approval-layout filter-layout">
		<div class="filter-box mb30"
			 v-if="!routeQuery">
			<el-row :gutter="10"
					v-show="searchToggle">
				<el-form ref="searchForm"
						 :model="searchForm"
						 label-width="90px"
						 size="small">
					<el-col :span="6">
						<el-form-item label="标题"
									  prop="title">
							<el-input v-model="searchForm.title"
									  placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="审批类型"
									  prop="approvalType">
							<el-select v-model="searchForm.approvalType"
									   placeholder="请选择">
								<el-option v-for="item in typeList"
										   :key="item.approvalType"
										   :label="item.approvalTypeName"
										   :value="item.approvalType">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="发起人"
									  prop="userName">
							<el-input v-model="searchForm.userName"
									  placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="发起单位"
									  prop="tenantName">
							<el-input v-model="searchForm.tenantName"
									  placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="发起时间"
									  prop="times">
							<el-date-picker v-model="searchForm.times"
											type="daterange"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											range-separator="-"
											start-placeholder="开始日期"
											end-placeholder="结束日期"
											@change="dateChange"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="18"
							class="text-right">
						<el-form-item>
							<el-button type="primary"
									   icon="el-icon-search"
									   @click="search">查询</el-button>
							<el-button @click="resetForm"
									   icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-col>
				</el-form>
			</el-row>

			<!-- 筛选折叠按钮 -->
			<div class="control-btn"
				 @click="searchToggle = !searchToggle">
				{{ searchToggle ? '点击收起' : '筛选' }}
				<svg-icon :icon-class="searchToggle ? 'pack-up' : 'pack-down'"
						  class="c-icon ml5"></svg-icon>
			</div>
		</div>
		<div class="filter-content primary-layout"
			 v-if="!routeQuery">
			<!-- 顶部 tab -->
			<div class="primary-header">
				<div class="left-tabs">
					<el-tabs v-model="auditState"
							 @tab-click="tabClick">
						<el-tab-pane label="待审核"
									 name="1001026001"></el-tab-pane>
						<el-tab-pane label="已审核"
									 name="1001026002"></el-tab-pane>
						<el-tab-pane label="驳回"
									 name="1001026003"></el-tab-pane>
						<el-tab-pane label="移交"
									 name="1001026041"></el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<!-- 底部表格 -->
			<div class="primary-content"
				 v-loading="loading">
				<CustomTableVxe v-if="tableShow"
								:requestConfigUrl="CustomTableVxeData.requestConfigUrl"
								:requestDataUrl="CustomTableVxeData.requestDataUrl"
								:btnEvent="CustomTableVxeData.btnEvent"
								:config="CustomTableVxeData.table.config"
								:data="CustomTableVxeData.table.data"
								:page="CustomTableVxeData.table.page"
								@page-change="pageListen">
					<div slot="发起单位"
						 slot-scope="scope">
						<el-tooltip effect="dark"
									:content="scope.row.sponsorUnit"
									placement="top">
							<span class="vxe-cell--label">{{ scope.row.sponsorUnitShortName }}</span>
						</el-tooltip>
					</div>
					<div slot="发起时间"
						 slot-scope="scope">
						<el-tooltip effect="dark"
									:content="scope.row.createDate"
									placement="top">
							<span class="vxe-cell--label">{{ scope.row.createDate.substr(0, 11) }}</span>
						</el-tooltip>
					</div>
					<div slot="状态"
						 slot-scope="scope">
						<span :class="dealClass(scope.row.status)">{{ dealStatus(scope.row.status) }}</span>
					</div>
				</CustomTableVxe>
			</div>
		</div>

		<el-dialog title="驳回原因"
				   :visible.sync="dialogShow"
				   center
				   append-to-body
				   :close-on-click-modal="false"
				   width="1000px">
			<el-form :form="reBackObj"
					 label-width="150px"
					 size="small">
				<el-form-item label="标题：">
					{{ reBackObj.title }}
				</el-form-item>
				<el-form-item label="审批类型：">
					{{ reBackObj.approvalTypeName }}
				</el-form-item>
				<el-form-item label="发起人：">
					{{ reBackObj.createUserName }}
				</el-form-item>
				<el-form-item label="发起单位：">
					{{ reBackObj.sponsorUnit }}
				</el-form-item>
				<el-form-item label="发起时间：">
					{{ reBackObj.createDate }}
				</el-form-item>
				<p class="line-split"></p>
				<el-form-item label="操作人：">
					{{ reBackObj.approveUserName }}
				</el-form-item>
				<el-form-item label="操作单位：">
					{{ reBackObj.approveUnitName }}
				</el-form-item>
				<el-form-item label="操作时间：">
					{{ reBackObj.approveTime }}
				</el-form-item>
				<el-form-item label="驳回原因：">
					{{ reBackObj.remark }}
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 审核页 -->
		<Archive v-if="routeQuery && routeQuery === 'archive'"
				 @resetTable="getData"></Archive>

		<!-- 移交他人审核 -->
		<AuditPersonList v-if="auditShow"
						 ref="AuditPersonList"
						 :rowData="rowData"
						 @closeDia="closeDia"></AuditPersonList>
	</div>
</template>
<script>
import ConfigJson from './config.json'
import { mapState } from 'vuex'
import Audit from './Audit'
import Archive from './Archive'
import { selectApprovalPage, selectApprovalType } from 'api/approval/approval'
import { getStatusStyle } from 'utils/tools.js'

import AuditPersonList from '@/views/Common/AuditPersonList'
export default {
	name: 'ApprovalIndex',
	components: { Audit, Archive, AuditPersonList },
	data(vm) {
		return {
			activeName: '', //当前激活选项卡
			loading: false, //表格加载
			dialogShow: false, //弹框展示
			audRemark: '', //驳回原因
			reBackObj: {},
			auditState: '1001026001',
			searchToggle: true,
			tableShow: true,
			typeList: [], //审批类型
			searchForm: {
				sname: '', //搜索条件
				title: '', //标题
				approvalType: '', //审批类型
				userName: '', //发起人
				tenantName: '', //发起单位
				startTime: '', //发起开始时间
				endTime: '', //发起结始时间
				status: '', //类型
				tenantId: '', //租户ID
			},
			CustomTableVxeData: {
				requestConfigUrl: '',
				requestDataUrl: '',
				btnEvent: [
					{
						name: '审核',
						icon: '',
						renderIcon: 'form-shenhe',
						disabled: (data) => {
							return false
						},
						show: (data) => {
							if (
								this.roleBtns.includes('audit') &&
								vm.auditState == '1001026001'
							) {
								return true
							}
						},
						click: (data) => {
							// 储存当前菜单
							let obj = {
								meta: this.$route.meta,
								path: this.$route.path,
								name: this.$route.name,
							}
							if (data.approvalType == '1006008005') {
								obj.resetName = '任务结项'
							}

							data.bpaId = data.reportId

							// 访问详情页面
							this.$setSessionItem(
								'currentRow',
								JSON.stringify(data)
							)
							// 储存当前菜单
							this.$setSessionItem(
								'activeMenu',
								JSON.stringify(obj)
							)
							// 储存当前菜单
							this.$setSessionItem(
								'activeName',
								JSON.stringify(this.auditState)
							)

							if (data.approvalType == '1006008004') {
								vm.$router.push({
									name: 'FeeDialog',
									query: {
										menu: 'approval',
										type: 'check',
										packId: data.id,
										projectId: data.projectId,
									},
								})
								return
							}

							if (data.approvalType == '1006008002') {
								vm.$router.push({
									name: 'AuditLook',
									query: {
										menu: 'approval',
										type: 'entrust',
										packId: data.id,
										projectId: data.projectId,
									},
								})
								return
							}

							if (data.approvalType == '1006008005') {
								vm.$router.push({
									name: 'AuditLook',
									query: {
										menu: 'approval',
										type: 'close',
										packId: data.id,
										projectId: data.projectId,
									},
								})
								return
							}

							// 审核归档信息
							if (data.approvalType == '1006008006') {
								let params = {
									id: data.id,
									busId: data.busId, // --任务id
									projectId: data.projectId, // 项目id
									packId: data.packId, // 标段id
									statusSign: '1', //
									auditType: data.auditType, // 审计任务类型码值
									auditTaskArchiveSign: '1', // 这里是列表页归档按钮查询资料时传值
									approveArchiveSign: '1', // 这里是审批查询资料时传值
									mode: 'check',
								}
								// 储存信息
								this.$setSessionItem(
									'archiveInfo',
									JSON.stringify(params)
								)

								vm.$router.push({
									name: 'ArchiveInfo',
									query: {
										menu: 'approval',
										type: 'archive',
									},
								})
								return
							}

							// 审计报告
							if (data.approvalType == '1006008007') {
								vm.$router.push({
									name: 'ReportAudit',
									query: {
										menu: 'project',
										type: 'audit',
									},
								})
								return
							}

							// 审计方案
							if (data.approvalType == '1006008010') {
								vm.$router.push({
									name: 'AuditPlan',
									query: {
										menu: 'project',
										type: 'audit',
									},
								})
								return
							}

							// 审计意见单
							if (data.approvalType == '1006008011') {
								vm.$router.push({
									name: 'AuditOpinion',
									query: {
										menu: 'project',
										type: 'audit',
									},
								})
								return
							}

							vm.$router.push({
								name: 'ProjectInfoLook',
								query: {
									menu: 'approval',
									type: 'audit',
									packId: data.id,
									projectId: data.projectId,
								},
							})
						},
					},
					{
						name: '查看',
						icon: '',
						renderIcon: 'form-look',
						disabled: (data) => {
							return false
						},
						show: (data) => {
							if (this.roleBtns.includes('view')) {
								return (
									vm.auditState == '1001026002' ||
									vm.auditState == '1001026003' ||
									vm.auditState == '1001026041'
								)
							}
						},
						click: (data) => {
							if (
								vm.auditState == '1001026002' ||
								vm.auditState == '1001026041'
							) {
								// 储存当前菜单
								let obj = {
									meta: this.$route.meta,
									path: this.$route.path,
									name: this.$route.name,
								}
								if (data.approvalType == '1006008005') {
									obj.resetName = '任务结项'
								}
								data.bpaId = data.reportId
								// 访问详情页面
								this.$setSessionItem(
									'currentRow',
									JSON.stringify(data)
								)
								// 储存当前菜单
								this.$setSessionItem(
									'activeName',
									JSON.stringify(this.auditState)
								)
								// 储存当前菜单
								this.$setSessionItem(
									'activeMenu',
									JSON.stringify(obj)
								)

								if (data.approvalType == '1006008004') {
									vm.$router.push({
										name: 'FeeDialog',
										query: {
											menu: 'approval',
											type: 'view',
											packId: data.id,
											projectId: data.projectId,
										},
									})
									return
								}

								if (data.approvalType == '1006008002') {
									vm.$router.push({
										name: 'AuditLook',
										query: {
											menu: 'approval',
											type: 'viewApproval',
											packId: data.id,
											projectId: data.projectId,
										},
									})
									return
								}

								if (data.approvalType == '1006008005') {
									vm.$router.push({
										name: 'AuditLook',
										query: {
											menu: 'approval',
											type: 'viewClose',
											packId: data.id,
											projectId: data.projectId,
										},
									})
									return
								}

								// 审核归档信息
								if (data.approvalType == '1006008006') {
									let params = {
										id: data.id,
										busId: data.busId, // --任务id
										projectId: data.projectId, // 项目id
										packId: data.packId, // 标段id
										statusSign: '1', //
										auditType: data.auditType, // 审计任务类型码值
										auditTaskArchiveSign: '1', // 这里是列表页归档按钮查询资料时传值
										approveArchiveSign: '1', // 这里是审批查询资料时传值
										mode: 'view',
										recordId: data.id, // 审批id
									}
									// 储存信息
									this.$setSessionItem(
										'archiveInfo',
										JSON.stringify(params)
									)
									vm.$router.push({
										name: 'ArchiveInfo',
										query: {
											menu: 'approval',
											type: 'archive',
										},
									})
									return
								}

								// 审计报告
								if (data.approvalType == '1006008007') {
									vm.$router.push({
										name: 'ReportAudit',
										query: {
											menu: 'approval',
											type: 'view',
										},
									})
									return
								}

								// 审计方案
								if (data.approvalType == '1006008010') {
									vm.$router.push({
										name: 'AuditPlan',
										query: {
											menu: 'approval',
											type: 'view',
										},
									})
									return
								}

								// 审计意见单
								if (data.approvalType == '1006008011') {
									vm.$router.push({
										name: 'AuditOpinion',
										query: {
											menu: 'project',
											type: 'view',
										},
									})
									return
								}

								vm.$router.push({
									name: 'ProjectInfoLook',
									query: {
										type: 'view',
										menu: 'approval',
										projectId: data.projectId,
									},
								})
							} else {
								this.audRemark = data.remark
								this.reBackObj = data
								this.dialogShow = true
							}
						},
					},
					{
						name: '移交他人审核',
						icon: '',
						renderIcon: 'form-otherPerson',
						disabled: (data) => {
							return false
						},
						show: (data) => {
							// if (this.roleBtns.includes('view')) {
							if (this.auditState == '1001026001') {
								let arr = [
									'1006008004',
									'1006008005',
									'1006008006',
									'1006008007',
									'1006008010',
									'1006008011',
								]
								return arr.includes(data.approvalType)
							}
							// }
						},
						click: (data) => {
							this.rowData = this.$utils.clone(data, true)
							this.auditShow = true
							setTimeout(() => {
								this.$refs.AuditPersonList.auditPersonObj.show = true
							}, 200)
						},
					},
				],
				table: {
					config: {},
					page: {
						currentPage: 1,
						pageSize: 10,
						total: 1,
					},
					data: [],
				},
			},
			auditShow: false,
			rowData: {},
		}
	},
	created() {
		this.CustomTableVxeData.table.config =
			ConfigJson.auditManagement.project_to_accept
		if (this.$getSessionItem('activeName')) {
			this.auditState = JSON.parse(this.$getSessionItem('activeName'))
			this.$removeSessionItem('activeName')
			this.searchForm.status = this.auditState
			this.getData()
		} else {
			this.getData()
		}
		// 获取审批类型
		this.getTypeList()
	},
	methods: {
		//
		closeDia(data) {
			this.auditShow = false
			this.rowData = {}
			this.getData()
		},

		// 获取审批数据列表
		getData() {
			this.loading = true
			this.tableShow = false
			let params = this.$utils.clone(this.searchForm, true)
			params.status = this.auditState
			params.tenantId = this.tenantId
			params.size = this.CustomTableVxeData.table.page.pageSize
			params.current = this.CustomTableVxeData.table.page.currentPage
			delete params.times
			selectApprovalPage(params)
				.then((res) => {
					let table = this.CustomTableVxeData.table
					table.data = res.data.records
					table.page.currentPage = parseInt(res.data.current)
					table.page.pageSize = parseInt(res.data.size)
					if (this.CustomTableVxeData.table.page.pageSize == -1) {
						table.page.total = parseInt(res.data.records.length)
					} else {
						table.page.total = parseInt(res.data.total)
					}
					setTimeout(() => {
						this.loading = false
						this.tableShow = true
					}, 200)
				})
				.catch(() => {
					this.loading = false
					this.tableShow = true
				})
		},

		// 获取审批类型
		getTypeList() {
			selectApprovalType({
				status: this.auditState,
			}).then((res) => {
				this.typeList = res.data
			})
		},

		// change事件
		dateChange(data) {
			this.searchForm.startTime = data ? data[0] : ''
			this.searchForm.endTime = data ? data[1] : ''
		},

		// tab点击
		tabClick(data) {
			this.getTypeList()
			this.CustomTableVxeData.table.page.currentPage = 1
			this.CustomTableVxeData.table.page.pageSize = 10
			this.getData()
			if (data.name == '1001026041') {
				this.CustomTableVxeData.table.config =
					ConfigJson.auditManagement.project_hander_other
				return
			}
			this.CustomTableVxeData.table.config =
				ConfigJson.auditManagement.project_to_accept
		},

		// 搜索
		search() {
			this.CustomTableVxeData.table.page.currentPage = 1
			this.getData()
		},

		// 重置
		resetForm() {
			this.$refs.searchForm.resetFields()
			this.searchForm.startTime = ''
			this.searchForm.endTime = ''
			this.search()
		},

		// 处理class
		dealClass(data) {
			let type = getStatusStyle(data)
			return type
		},

		// 处理状态
		dealStatus(data) {
			let msg = ''
			switch (data) {
				case '1001026001':
					msg = '待审核'
					break
				case '1001026002':
					msg = '已审核'
					break
				case '1001026003':
					msg = '驳回'
					break
			}
			return msg
		},

		// 从审批页返回
		goBack() {
			this.routeQuery = false
		},

		// 分页监听
		pageListen(obj) {
			if (obj.type === 'current') {
				this.CustomTableVxeData.table.page.currentPage = obj.currentPage
			} else {
				this.CustomTableVxeData.table.page.currentPage = 1
				this.CustomTableVxeData.table.page.pageSize = obj.pageSize
			}
			this.getData()
		},
	},
	computed: {
		...mapState('app', { isBack: (state) => state.isBack }),
		routeQuery() {
			return this.$route.query.type
		},
		...mapState('user', {
			tenantId: (state) => state.tenantId,
		}),
		...mapState('menu', {
			roleBtns: (state) => state.roleBtns,
		}),
	},
	watch: {
		// 监听 筛选元素的 切换展示 重新调整表格高度
		searchToggle() {
			this.tableShow = false
			setTimeout(() => {
				this.tableShow = true
			}, 50)
		},
	},
}
</script>
<style lang='less' scoped>
.my-approval-layout {
	.filter-box {
		padding: 30px 0 0 0;
	}
}
.line-split {
	height: 3px;
	margin: 15px auto;
	border-bottom: 3px dashed #eee;
}
</style>
